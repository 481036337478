import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from '../../../util.js';
import {FacebookProvider, Like} from 'react-facebook';

export const FooterFacebookIcon = connect(state => ({
  facebook: state.config.socialMedia.facebook,
  facebookAppId: state.config.socialMedia.facebookAppId,
  t: state.t,
  app: state.app,
}))(class extends Component {
  static propTypes = {
    facebook: PropTypes.string.isRequired,
    facebookAppId: PropTypes.string.isRequired,
    t: PropTypes.any.isRequired,
    app: PropTypes.any.isRequired,
  }

  render() {
    const app = this.props.app;
    const showFaces = app.deviceSameOrLargerThan('lg');

    const activeConfig = {width: 130, size: 'large', noShare: false};

    return (
      <FacebookProvider appId={this.props.facebookAppId} language={activeConfig.language || this.props.t('facebookLanguage')}>
        <Like
          href={this.props.facebook}
          colorScheme="dark"
          showFaces={showFaces}
          share={!activeConfig.noShare}
          width={activeConfig.width}
          size={activeConfig.size || 'large' /*small | large*/}
          layout={activeConfig.layout || 'standard' /*standard | button_count | button | box_count*/}
        />
      </FacebookProvider>
    );
  }
});
